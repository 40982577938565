import { Text } from "@chakra-ui/layout"
import React from "react"
import { COLORS } from "./zipti_colors"

export interface ZiptiTextProps {
  w?: string
  h?: string
  fontWeight?: string
  color?: string
  fontSize?: string
  position?: string
  left?: string
  top?: string
  placeholder?: string
  mt?: string | any
  ml?: string | any
  mr?: string | any
  mb?: string | any
  textAlign?: string
  children: React.ReactNode
  onClick?: any
  cursor?: string
}

const ZiptiText: React.FC<ZiptiTextProps> = props => {
  return (
    <Text
      fontFamily="Helvetica"
      fontSize="16px"
      color={COLORS.PURPLE}
      textOverflow="ellipsis"
      overflow="hidden"
      align="center"
      {...props}
    >
      {props.children}
    </Text>
  )
}

export default ZiptiText
