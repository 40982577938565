import * as React from "react"
import { Box, Center } from "@chakra-ui/layout"
import { Image } from "@chakra-ui/image"

import ziptiLogo from "../images/ziptiLogo.svg"
import ZiptiText from "../components/shared/zipti_text"

const NotFoundPage = () => (
  <Box>
    <Center>
      <Image mt="20vh" w="30vh" h="30vh" src={ziptiLogo} />
    </Center>
    <Center>
      <ZiptiText mt="10vh" fontWeight="bold">
        Page not found!
      </ZiptiText>
    </Center>
  </Box>
)

export default NotFoundPage
